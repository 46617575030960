exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-athlete-application-js": () => import("./../../../src/pages/athlete-application.js" /* webpackChunkName: "component---src-pages-athlete-application-js" */),
  "component---src-pages-athletes-js": () => import("./../../../src/pages/athletes.js" /* webpackChunkName: "component---src-pages-athletes-js" */),
  "component---src-pages-combine-js": () => import("./../../../src/pages/combine.js" /* webpackChunkName: "component---src-pages-combine-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-first-5-min-of-featured-match-js": () => import("./../../../src/pages/first-5min-of-featured-match.js" /* webpackChunkName: "component---src-pages-first-5-min-of-featured-match-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rules-faqs-js": () => import("./../../../src/pages/rules-faqs.js" /* webpackChunkName: "component---src-pages-rules-faqs-js" */),
  "component---src-pages-wallpaper-js": () => import("./../../../src/pages/wallpaper.js" /* webpackChunkName: "component---src-pages-wallpaper-js" */),
  "component---src-templates-athletes-js": () => import("./../../../src/templates/athletes.js" /* webpackChunkName: "component---src-templates-athletes-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-watch-js": () => import("./../../../src/templates/watch.js" /* webpackChunkName: "component---src-templates-watch-js" */)
}

